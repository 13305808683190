export const transformProperty = (values: any) => ({
  modelType: "Property",
  semanticId: {
    type: "ExternalReference",
    keys: [
      {
        type: "GlobalReference",
        value: values?.semantic_id,
      },
    ],
  },
  value: values?.value,
  valueType: "xs:string",
  description: [
    {
      language: "en-us",
      text: values?.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values?.display_name,
    },
  ],
  idShort: values?.id_short,
});

export const transformMultiLanguageProperty = (values: any) => ({
  modelType: "MultiLanguageProperty",
  semanticId: {
    keys: [
      {
        type: "GlobalReference",
        value: values?.semantic_id,
      },
    ],
    type: "ExternalReference",
  },
  value: values?.multilanguage_property.map((val: any, index: number) => ({
    language: val,
    text: values.language_description[index],
  })),
  description: [
    {
      language: "en-us",
      text: values.description,
    },
    {
      language: "de",
      text: values.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values.display_name,
    },
    {
      language: "de",
      text: values.display_name,
    },
  ],
  idShort: values.id_short,
});

export const transformFileProperty = (values: any) => ({
  modelType: "File",
  contentType: values?.content_type,
  value: values?.value,
  semanticId: {
    keys: [
      {
        type: "GlobalReference",
        value: values.semantic_id,
      },
    ],
    type: "ExternalReference",
  },
  description: [
    {
      language: "en-us",
      text: values?.description,
    },
    {
      language: "de",
      text: values?.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values?.display_name,
    },
    {
      language: "de",
      text: values?.display_name,
    },
  ],
  idShort: values.id_short,
});

export const transformRangeProperty = (values: any) => ({
  modelType: "Range",
  semanticId: {
    keys: [
      {
        type: "GlobalReference",
        value: values.semantic_id,
      },
    ],
    type: "ExternalReference",
  },
  max: values?.max,
  min: values?.min,
  valueType: values?.valueType,
  description: [
    {
      language: "en-us",
      text: values?.description,
    },
    {
      language: "de",
      text: values?.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values?.display_name,
    },
    {
      language: "de",
      text: values?.display_name,
    },
  ],
  idShort: values.id_short,
});

export const transformReferenceElement = (values: any) => ({
  modelType: "ReferenceElement",
  semanticId: {
    keys: [
      {
        type: "GlobalReference",
        value: values?.semantic_id,
      },
    ],
    type: "ExternalReference",
  },
  description: [
    {
      language: "en-us",
      text: values?.description,
    },
    {
      language: "de",
      text: values?.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values?.display_name,
    },
    {
      language: "de",
      text: values?.display_name,
    },
  ],
  idShort: values?.id_short,
  value: {
    keys: values?.value.map((val: any) => ({
      type: "Submodel",
      value: val,
    })),
    type: "ExternalReference",
  },
});

export const transformRelationshipElement = (values: any) => ({
  modelType: "RelationshipElement",
  semanticId: {
    keys: [
      {
        type: "GlobalReference",
        value: values.semantic_id,
      },
    ],
    type: "ExternalReference",
  },
  category: "PARAMETER",
  description: [
    {
      language: "en-us",
      text: values?.description,
    },
    {
      language: "de",
      text: values?.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values?.display_name,
    },
    {
      language: "de",
      text: values?.display_name,
    },
  ],
  idShort: values.id_short,
  first: {
    keys: values?.first?.map((val: any) => ({
      type: "Submodel",
      value: val,
    })),
    type: "ModelReference",
  },
  second: {
    keys: values?.second?.map((val: any) => ({
      type: "Submodel",
      value: val,
    })),
    type: "ModelReference",
  },
});

export const transformSubmodelElementCollection = (values: any) => ({
  modelType: "SubmodelElementCollection",
  semanticId: {
    keys: [
      {
        type: "GlobalReference",
        value: values?.semantic_id,
      },
    ],
    type: "ExternalReference",
  },
  description: [
    {
      language: "en-us",
      text: values?.description,
    },
    {
      language: "de",
      text: values?.description,
    },
  ],
  displayName: [
    {
      language: "en-us",
      text: values?.display_name,
    },
    {
      language: "de",
      text: values?.display_name,
    },
  ],
  idShort: values?.id_short,
  value: values?.SubmodelElementCollection?.map((item: any) =>
    item?.map((element: any) => {
      if (element?.SubmodelElementCollection) {
        return transformSubmodelElementCollection(element);
      } else if (element?.first) {
        return transformRelationshipElement(element);
      } else if (element?.min) {
        return transformRangeProperty(element);
      } else if (element?.multilanguage_property?.length) {
        return transformMultiLanguageProperty(element);
      } else if (element?.content_type) {
        return transformFileProperty(element);
      } else if (Array.isArray(element?.value)) {
        return transformReferenceElement(element);
      } else {
        return transformProperty(element);
      }
    })
  ).flat(),
});

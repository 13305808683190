import { Card } from "antd";
import Property from "../elements/property";
import MultiLanguageProperty from "../elements/multiLanguageProperty";
import ReferenceElement from "../elements/referenceElement";
import RelationshipElement from "../elements/relationshipElement";
import File from "../elements/file";
import Range from "../elements/range";
import SubmodelElementCollection from "../elements/submodelElementCollection";

const componentMapping: any = {
  Property: Property,
  MultiLanguageProperty: MultiLanguageProperty,
  File: File,
  Range: Range,
  ReferenceElement: ReferenceElement,
  RelationshipElement: RelationshipElement,
  SubmodelElementCollection: SubmodelElementCollection,
  // AnnotatedRelationshipElement: AnnotatedRelationshipElement,
  // Entity: Entity,
};

const SubmodelElementsDetails = (props: any) => {
  return (
    <Card title={"Submodel Elements"}>
      {props?.fields.map((field: any, index: any) => {
        const element = props?.subModelElements.find(
          (el: any) => el.fieldKey === field.name
        );
        console.log("element type",element?.type)
        if (!element) return null;
        const Component = componentMapping[element.type];
        return (
          <>
            {props?.subModelElements.length && (
              <Component
                key={element.key}
                fields={[field]}
                languageCount={props?.languageCount}
                referenceCount={props?.referenceCount}
                relationCount={props?.relationCount}
                form={props?.form}
                readonly={true}
                submodelElementCollection ={props?.mappedSubmodelElementCollection}
              />
            )}
          </>
        );
      })}
    </Card>
  );
};

export default SubmodelElementsDetails;

import { Button, Card, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Col, Row, Table } from "react-bootstrap";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const File = (props: any) => {
  const form = props?.form.getFieldValue();
  const Data = form?.Elements;
  const readonly = props?.readonly ? props?.readonly : false;

  const handleClick = (val: any) => {
    if (val) {
      window.open(val, "_blank");
    }
  };

  const fileValue = (data: any) => {
    if (!data) {
      return "";
    }
    const result = data?.filter((item: any, index: any) => {
      return item?.content_type;
    });
    return result?.[0]?.value;
  };
  return (
    <>
      {props?.fields?.map(({ key, name, ...restField }: any) => (
        <Card
          size="small"
          title={"File"}
          extra={
            !readonly && (
              <Button size="small" danger onClick={() => props?.remove(name)}>
                Remove
              </Button>
            )
          }
          className="mb-4"
        >
          <Table key={key} bordered>
            <thead>
              <tr>
                <th>ID Short</th>
                <th>Display Name</th>
                <th>Semantic ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "id_short"]}
                    rules={[
                      { required: true, message: "Please enter ID Short" },
                    ]}
                  >
                    <Input
                      bordered={false}
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter ID Short"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "display_name"]}
                    rules={[
                      { required: true, message: "Please enter Display Name" },
                    ]}
                  >
                    <Input
                      bordered={false}
                      size="large"
                      placeholder="Enter name"
                      readOnly={readonly}
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "semantic_id"]}
                    rules={[
                      { required: true, message: "Please enter Semantic ID" },
                    ]}
                  >
                    <Input
                      bordered={false}
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter Semantic ID"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "description"]}
                    rules={[
                      { required: true, message: "Please enter description" },
                    ]}
                  >
                    <Input
                      bordered={false}
                      readOnly={readonly}
                      size="large"
                      placeholder="Enter description"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered={true}>
            <thead>
              <tr>
                <th>Value</th>
                <th>Content Type</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    label="Value"
                    name={[name, "value"]}
                    rules={[{ required: true, message: "Please enter value" }]}
                  >
                    {readonly ? (
                      <a
                        href={"#"}
                        onClick={() => handleClick(fileValue(Data))}
                        style={{ padding: 10, display: "inline-block" }}
                      >
                        {fileValue(Data)}
                      </a>
                    ) : (
                      <Input
                        bordered={false}
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter value"
                        style={{ padding: 10 }}
                      />
                    )}
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    label="Content Type"
                    name={[name, "content_type"]}
                    rules={[
                      { required: true, message: "Please enter content type" },
                    ]}
                  >
                    <Input
                      bordered={false}
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter content type"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                {/* <td style={{ width: 10 }}>
                <Button>Add</Button>
              </td> */}
              </tr>
            </tbody>
          </Table>
        </Card>
      ))}
    </>
  );
};

export default File;

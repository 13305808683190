import {
  Button,
  Card,
  Form,
  Spin,
  Tree,
  TreeDataNode,
  TreeProps,
  Table as AntTable,
} from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import SubmodelDetails from "../../subModalScreen/components/submodelDetails";
import SubmodelElementsDetails from "../../subModalScreen/components/submodelElementsDetails";
import { useEffect, useState } from "react";
import API from "../../../../config/api";
import { GET } from "../../../../utils/apiCall";
import Property from "../../subModalScreen/elements/property";
import MultiLanguageProperty from "../../subModalScreen/elements/multiLanguageProperty";
import ReferenceElement from "../../subModalScreen/elements/referenceElement";
import RelationshipElement from "../../subModalScreen/elements/relationshipElement";
import File from "../../subModalScreen/elements/file";
import Range from "../../subModalScreen/elements/range";
import { Table } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import SubModelElementCondensedView from "../../subModalScreen/components/submodelDetailsCondensedView";

const componentMapping: any = {
  Property: Property,
  MultiLanguageProperty: MultiLanguageProperty,
  File: File,
  Range: Range,
  ReferenceElement: ReferenceElement,
  RelationshipElement: RelationshipElement,
  // AnnotatedRelationshipElement: AnnotatedRelationshipElement,
  // Entity: Entity,
};
interface SubModelElement {
  type: keyof typeof componentMapping;
  key: number;
  fieldKey: number;
}

function SubModalDetails(props: any) {
  let mappedSubmodelElementCollection: any;
  const navigate = useNavigate();
  const id = btoa(props?.item?.keys[0]?.value);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const [languageCount, setLanguageCount] = useState<number>(1);
  const [referenceCount, setReferenceCount] = useState<number>(1);
  const [relationCount, setRelationCount] = useState<number>(1);
  const [subModelElements, setSubModelElements] = useState<SubModelElement[]>(
    []
  );
  const [extendedView, setExtendedView] = useState<boolean>(false);
  const [subModelElementsCollection, setSubModelElementsCollection] = useState<
    SubModelElement[]
  >([]);
  const [submodelElementCollection, setSubmodelElementCollection] = useState(
    []
  );
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleExpand = (expanded: boolean, record: any) => {
    const key = record.key;
    setExpandedRows(
      expanded ? [...expandedRows, key] : expandedRows.filter((k) => k !== key)
    );
  };

  const mapSubElements = (subElement: any, index: number) => {
    if (subElement.submodelElements) {
      // Map the nested submodelElements
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
        // submodelElements: subElement.submodelElements.map((nestedSubElement: any, nestedIndex: number) =>
        //   mapSubElements(nestedSubElement, nestedIndex)
        // ),
      };
    } else if (subElement.value && Array.isArray(subElement.value)) {
      // Map the value if it's an array
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
        // value: subElement.value.map((nestedSubElement: any, nestedIndex: number) =>
        //   mapSubElements(nestedSubElement, nestedIndex)
        // ),
      };
    } else {
      // No submodelElements or value, just map the element itself
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
      };
    }
  };

  const mapSubElementsRecursive = (elements: any[], parentIndex = 0): any[] => {
    return elements.map((subElement: any, index: number) => {
      const mappedElement = {
        type: subElement.modelType,
        key: Date.now() + parentIndex + index,
        fieldKey: index,
      };

      if (subElement.submodelElements) {
        return {
          ...mappedElement,
          submodelElements: mapSubElementsRecursive(
            subElement.submodelElements,
            index
          ),
        };
      }

      if (subElement.value && Array.isArray(subElement.value)) {
        return {
          ...mappedElement,
          value: mapSubElementsRecursive(subElement.value, index),
        };
      }

      return mappedElement;
    });
  };

  const updateStates = (data: any) => {
    setSubModelElements(
      data.submodelElements.map((subElement: any, index: number) =>
        mapSubElements(subElement, index)
      )
    );
    const submodelElementCollection = data?.submodelElements?.find(
      (subElement: any) => subElement.modelType === "SubmodelElementCollection"
    );
    if (submodelElementCollection) {
      mappedSubmodelElementCollection = mapSubElementsRecursive(
        submodelElementCollection.value
      );
      setSubModelElementsCollection(mappedSubmodelElementCollection);
      localStorage.setItem(
        "submodelElements",
        JSON.stringify(mappedSubmodelElementCollection)
      );
    }
  };

  const renderTableContent = (item: any) => {
    if (item?.modelType === "SubmodelElementCollection") {
      return;
    }
    if (item?.modelType === "File" || item?.modelType === "Property") {
      return <td style={{ padding: 10 }}>{item?.value}</td>;
    }

    if (item?.modelType === "Range") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              <tr>
                <th>Minimum</th>
                <th>Maximum</th>
              </tr>
              <tr>
                <td>{item?.min}</td>
                <td>{item?.max}</td>
              </tr>
            </tbody>
          </Table>
        </td>
      );
    }

    if (item?.modelType === "ReferenceElement") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              {item?.value?.keys?.map((singleItem: any, index: number) => (
                <tr key={index}>
                  <td>{singleItem?.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      );
    }

    if (item?.modelType === "MultiLanguageProperty") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              {item?.value?.map((singleItem: any, index: number) => (
                <tr key={index}>
                  <td>{singleItem?.language}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      );
    }

    if (item?.modelType === "RelationshipElement") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              <tr>
                <th>First</th>
                <th>Second</th>
              </tr>
              {Array.from({
                length: Math.max(
                  item?.first?.keys?.length || 0,
                  item?.second?.keys?.length || 0
                ),
              }).map((_, index) => (
                <React.Fragment key={index}>
                  {index < item?.second?.keys?.length && (
                    <tr key={index}>
                      <td>{item?.first?.keys[index]?.value}</td>
                      <td>{item?.second?.keys[index]?.value}</td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </td>
      );
    }

    // Default case if modelType doesn't match any of the above
    return <td style={{ padding: 10 }}>No content available</td>;
  };

  const renderSubmodelData: any = (submodels: any[]) => {
    return submodels.map((submodel, index) => ({
      key: `submodel-${index}`,
      type: submodel?.modelType,
      idShort: submodel.idShort,
      value: renderTableContent(submodel),
      children:
        submodel.modelType === "SubmodelElementCollection" &&
        submodel.value?.length > 0
          ? renderSubmodelData(submodel.value)
          : undefined,
    }));
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const encodedSearch = id;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}/${encodedSearch}`;
      const response: any = await GET(url);
      if (response?.id) {
        setDetails(response);
        const submodelElement = response?.submodelElements.find(
          (item: any) => item.modelType === "SubmodelElementCollection"
        );
        if (submodelElement && submodelElement?.value) {
          const treeDataStructure: any = renderSubmodelData(
            [submodelElement] || []
          );
          setSubmodelElementCollection(treeDataStructure);
          // setSubmodelElementCollection(submodelElement?.value);
        }
        populateForm(response);
      }
    } catch (error: any) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const transformElements = (data: any) => {
    switch (data?.modelType) {
      case "Property":
        return {
          ...transformSubmodels(data),
          value: data?.value,
        };
      case "MultiLanguageProperty":
        setLanguageCount(data?.value?.length);
        return {
          ...transformSubmodels(data),
          multilanguage_property: data?.value?.map(
            (item: any) => item?.language
          ),
          language_description: data?.value?.map((item: any) => item?.text),
        };
      case "File":
        return {
          ...transformSubmodels(data),
          value: data?.value,
          content_type: data?.contentType,
        };
      case "Range":
        return {
          ...transformSubmodels(data),
          valueType: data?.valueType,
          min: data?.min,
          max: data?.max,
        };
      case "ReferenceElement":
        setReferenceCount(data?.value?.keys?.length);
        return {
          ...transformSubmodels(data),
          value: data?.value?.keys?.map((item: any) => item?.value),
        };
      case "RelationshipElement":
        setRelationCount(data?.first?.keys?.length);
        return {
          ...transformSubmodels(data),
          first: data?.first?.keys?.map((item: any) => item?.value),
          second: data?.second?.keys?.map((item: any) => item?.value),
        };
      case "SubmodelElementCollection":
        return {
          ...transformSubmodels(data),
          SubmodelElementCollection: [
            data?.value?.map((item: any) => {
              return transformElements(item);
            }),
          ],
        };
      default:
        return null;
    }
  };

  const populateForm = (data: any) => {
    let transformedElements: any[] = [];
    data?.submodelElements?.map((subElement: any, index: number) => {
      // if (subElement?.modelType === "SubmodelElementCollection") {
      //   transformedSubmodelElements = transformElements(subElement);
      //   form.setFieldsValue({
      //     ...(subElement?.idShort && { id_shortSC: subElement.idShort }),
      //     ...(subElement?.semanticId?.keys?.[0]?.value && {
      //       semantic_idSC: subElement.semanticId.keys[0].value,
      //     }),
      //     ...(subElement?.displayName?.[0]?.text && {
      //       display_nameSC: subElement.displayName[0].text,
      //     }),
      //     ...(subElement?.description?.[0]?.text && {
      //       descriptionSC: subElement.description[0].text,
      //     })
      //   });
      // } else {
      //   transformedElements = transformElements(data);
      // }
      transformedElements.push(transformElements(subElement));
    });
    form.setFieldsValue({
      id: data?.id,
      ...(data?.idShort && { id_short: data.idShort }),
      ...(data?.semanticId?.keys?.[0]?.value && {
        semantic_id: data.semanticId.keys[0].value,
      }),
      ...(data?.displayName?.[0]?.text && {
        display_name: data.displayName[0].text,
      }),
      ...(data?.description?.[0]?.text && {
        description: data.description[0].text,
      }),
      Elements: transformedElements,
      // SubmodelElementCollection: transformedSubmodelElements,
    });
    updateStates(data);
  };

  const transformSubmodels = (data: any) => {
    return {
      id_short: data?.idShort,
      display_name: data?.displayName?.length
        ? data?.displayName?.[0]?.text
        : null,
      semantic_id: data?.semanticId?.keys?.length
        ? data?.semanticId?.keys?.[0]?.value
        : null,
      description: data?.description?.length
        ? data?.description?.[0]?.text
        : null,
    };
  };

  const columns: any = [
    {
      title: "ID Short",
      dataIndex: "idShort",
      key: "idShort",
      width: "26%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data: any = submodelElementCollection;
  return (
    <Spin spinning={loading}>
      {props?.type === "details" ? (
        <Card
          title={details?.idShort ? details?.idShort : details?.id}
          extra={
            <div className="d-flex gap-3">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  setExtendedView(!extendedView);
                }}
              >
                {extendedView ? "Condensed View" : "Extended View"}
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  navigate(`/auth/SubModelScreenFrom/${id}`);
                }}
              >
                Edit
              </Button>
            </div>
          }
        >
          {extendedView ? (
            <>
              <SubmodelDetails details={details} readonly={true} />
              {details?.submodelElements?.length && (
                <Form form={form} layout="vertical">
                  <div style={{ paddingBottom: "10vh" }}>
                    <br />

                    <Form.List name="Elements">
                      {(fields, { add, remove }) => (
                        <SubmodelElementsDetails
                          fields={fields}
                          subModelElements={subModelElements}
                          form={form}
                          languageCount={languageCount}
                          referenceCount={referenceCount}
                          relationCount={relationCount}
                          mappedSubmodelElementCollection={
                            mappedSubmodelElementCollection
                          }
                        />
                      )}
                    </Form.List>
                  </div>
                </Form>
              )}
            </>
          ) : (
            <div style={{ overflowX: "auto" }}>
              <Table bordered style={{ margin: 0, width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "26%" }}  >ID Short</th>
                    <th>Value</th>
                  </tr>
                </thead>
                {details?.submodelElements?.map((item: any) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          {item?.modelType === "SubmodelElementCollection" ? (
                            <td style={{ padding: 0 }} colSpan={2}>
                              <AntTable
                                bordered
                                columns={columns}
                                dataSource={data}
                                expandable={{
                                  expandedRowKeys: expandedRows,
                                  onExpand: handleExpand,
                                  expandIcon: ({
                                    expanded,
                                    onExpand,
                                    record,
                                  }) =>
                                    record.type ===
                                    "SubmodelElementCollection" ? (
                                      expanded ? (
                                        <DownOutlined
                                          onClick={(e) => onExpand(record, e)}
                                        />
                                      ) : (
                                        <RightOutlined
                                          onClick={(e) => onExpand(record, e)}
                                        />
                                      )
                                    ) : null,
                                }}
                                pagination={false}
                                showHeader={false}
                              />
                            </td>
                          ) : (
                            <>
                              <td style={{ padding: 10 }}>{item?.idShort}</td>
                              {item?.modelType === "File" && (
                                <td style={{ padding: 10 }}>{item?.value}</td>
                              )}
                              {item?.modelType === "Property" && (
                                <td style={{ padding: 10 }}>{item?.value}</td>
                              )}
                              {item?.modelType === "Range" && (
                                <td style={{ padding: 0 }}>
                                  <Table bordered style={{ margin: 0 }}>
                                    <tbody>
                                      <tr>
                                        <th>Minimum</th>
                                        <th>Maximum</th>
                                      </tr>
                                      <tr>
                                        <td>{item?.min}</td>
                                        <td>{item?.max}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              )}
                              {item?.modelType === "ReferenceElement" && (
                                <td style={{ padding: 0 }}>
                                  <Table bordered style={{ margin: 0 }}>
                                    <tbody>
                                      {item?.value?.keys?.map(
                                        (singleItem: any) => {
                                          return (
                                            <tr>
                                              <td>{singleItem?.value}</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </td>
                              )}
                              {item?.modelType === "MultiLanguageProperty" && (
                                <td style={{ padding: 0 }}>
                                  <Table bordered style={{ margin: 0 }}>
                                    <tbody>
                                      {item?.value?.map((singleItem: any) => {
                                        return (
                                          <tr>
                                            <td>{singleItem?.language}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </td>
                              )}
                              {item?.modelType === "RelationshipElement" && (
                                <td style={{ padding: 0 }}>
                                  <Table bordered style={{ margin: 0 }}>
                                    <tbody>
                                      <tr>
                                        <th>First </th>
                                        <th>Second </th>
                                      </tr>
                                      {item &&
                                        Array.from({
                                          length: Math.max(
                                            item?.first?.keys?.length || 0,
                                            item?.second?.keys?.length || 0
                                          ),
                                        }).map((_, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              {index <
                                                item?.second?.keys?.length && (
                                                <tr>
                                                  <td>
                                                    {
                                                      item.first.keys[index]
                                                        ?.value
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      item.second.keys[index]
                                                        ?.value
                                                    }
                                                  </td>
                                                </tr>
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                    </tbody>
                                  </Table>
                                </td>
                              )}
                              {item?.modelType ===
                                "SubModel Element Collection" && (
                                <td style={{ padding: 10 }}></td>
                              )}
                              {item?.modelType ===
                                "SubmodelElementCollection" && (
                                <td style={{ padding: 0 }}>
                                  {/* <Table bordered style={{ margin: 0 }}>
                               <tbody>
                                 {item?.value?.length &&
                                   item?.value?.map(
                                     (items: any, index: number) => {
                                       console.log("look", item);
                                       return (
                                         <tr key={item?.id}>
                                           <td>{items?.modelType}</td>
    
                                           {index === 0 && (
                                             <td
                                               rowSpan={item?.value?.length}
                                               style={{
                                                 verticalAlign: "middle",
                                                 textAlign: "center",
                                               }}
                                             >
                                               <div
                                                 style={{
                                                   display: "flex",
                                                   justifyContent: "center",
                                                   alignItems: "center",
                                                   height: "100%",
                                                 }}
                                               >
                                                 <Button
                                                   type="primary"
                                                   style={{ border: "none" }}
                                                   ghost
                                                   onClick={() => {
                                                     setExtendedView(
                                                       !extendedView
                                                     );
                                                   }}
                                                 >
                                                   {extendedView
                                                     ? "Condensed View"
                                                     : "Extended View"}
                                                 </Button>
                                               </div>
                                             </td>
                                           )}
                                         </tr>
                                       );
                                     }
                                   )}
                               </tbody>
                             </Table> */}
                                  {item?.value?.length && (
                                    <AntTable
                                      columns={columns}
                                      dataSource={data}
                                      expandable={{
                                        expandedRowKeys: expandedRows,
                                        onExpand: handleExpand,
                                        expandIcon: ({
                                          expanded,
                                          onExpand,
                                          record,
                                        }) =>
                                          record.type ===
                                          "SubmodelElementCollection" ? (
                                            expanded ? (
                                              <DownOutlined
                                                onClick={(e) =>
                                                  onExpand(record, e)
                                                }
                                              />
                                            ) : (
                                              <RightOutlined
                                                onClick={(e) =>
                                                  onExpand(record, e)
                                                }
                                              />
                                            )
                                          ) : null,
                                      }}
                                      pagination={false}
                                    />
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      </tbody>
                    </>
                  );
                })}
              </Table>
            </div>
          )}
        </Card>
      ) : (
        <>
          <SubmodelDetails
            details={details}
            onDelete={(val: any) => props?.onDeleteSubModal(val)}
          />
          {details?.submodelElements?.length && (
            <Form form={form} layout="vertical">
              <div style={{ paddingBottom: "10vh" }}>
                <br />

                <Form.List name="Elements">
                  {(fields, { add, remove }) => (
                    <SubmodelElementsDetails
                      fields={fields}
                      subModelElements={subModelElements}
                      form={form}
                      languageCount={languageCount}
                      referenceCount={referenceCount}
                      relationCount={relationCount}
                    />
                  )}
                </Form.List>
              </div>
            </Form>
          )}
        </>
      )}
    </Spin>
  );
}

export default SubModalDetails;

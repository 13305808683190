import {
  Button,
  Card,
  Dropdown,
  Form,
  MenuProps,
  Spin,
  Tree,
  TreeDataNode,
  TreeProps,
  message,
  Table as AntTable,
} from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import PageHeader from "../../components/pageHeader";
import { Table } from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCall";
import SubmodelDetails from "./components/submodelDetails";
import SubmodelElementsDetails from "./components/submodelElementsDetails";
import File from "./elements/file";
import MultiLanguageProperty from "./elements/multiLanguageProperty";
import Property from "./elements/property";
import Range from "./elements/range";
import ReferenceElement from "./elements/referenceElement";
import RelationshipElement from "./elements/relationshipElement";
import SubmodelElementCollection from "./elements/submodelElementCollection";
import SubModelElementCondensedView from "./components/submodelDetailsCondensedView";

const componentMapping: any = {
  Property: Property,
  MultiLanguageProperty: MultiLanguageProperty,
  File: File,
  Range: Range,
  ReferenceElement: ReferenceElement,
  RelationshipElement: RelationshipElement,
  SubModelElementCollection: SubmodelElementCollection,
  // AnnotatedRelationshipElement: AnnotatedRelationshipElement,
  // Entity: Entity,
};
interface SubModelElement {
  type: keyof typeof componentMapping;
  key: number;
  fieldKey: number;
}

function SubModelsDetails() {
  let mappedSubmodelElementCollection: any;
  const [form] = Form.useForm();
  // const formWatchData = Form.useWatch((values) => values, {
  //   form: form,
  //   preserve: true,
  // });
  // console.log("form Data", formWatchData);
  const [share, setShare] = useState(false);
  const [publish, setPublish] = useState(false);
  const [exports, setExport] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const navigate = useNavigate();
  const [subModelElements, setSubModelElements] = useState<SubModelElement[]>(
    []
  );
  const [subModelElementsCollection, setSubModelElementsCollection] = useState<
    SubModelElement[]
  >([]);
  // const [submodelCollectionElements, setSubmodelCollectionElements] = useState<
  //   SubModelElement[]
  // >([]);
  const [languageCount, setLanguageCount] = useState<number>(1);
  const [referenceCount, setReferenceCount] = useState<number>(1);
  const [relationCount, setRelationCount] = useState<number>(1);
  const [extendedView, setExtendedView] = useState<boolean>(false);
  const [submodelElementCollection, setSubmodelElementCollection] = useState(
    []
  );
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const { id }: any = useParams();
  // const decodedId = atob(id);

  useEffect(() => {
    fetchData();
  }, []);

  const renderTableContent = (item: any) => {
    if (item?.modelType === "SubmodelElementCollection") {
      return;
    }
    if (item?.modelType === "File" || item?.modelType === "Property") {
      return <td style={{ padding: 10 }}>{item?.value}</td>;
    }

    if (item?.modelType === "Range") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              <tr>
                <th>Minimum</th>
                <th>Maximum</th>
              </tr>
              <tr>
                <td>{item?.min}</td>
                <td>{item?.max}</td>
              </tr>
            </tbody>
          </Table>
        </td>
      );
    }

    if (item?.modelType === "ReferenceElement") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              {item?.value?.keys?.map((singleItem: any, index: number) => (
                <tr key={index}>
                  <td>{singleItem?.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      );
    }

    if (item?.modelType === "MultiLanguageProperty") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              {item?.value?.map((singleItem: any, index: number) => (
                <tr key={index}>
                  <td>{singleItem?.language}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      );
    }

    if (item?.modelType === "RelationshipElement") {
      return (
        <td style={{ padding: 0 }}>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              <tr>
                <th>First</th>
                <th>Second</th>
              </tr>
              {Array.from({
                length: Math.max(
                  item?.first?.keys?.length || 0,
                  item?.second?.keys?.length || 0
                ),
              }).map((_, index) => (
                <React.Fragment key={index}>
                  {index < item?.second?.keys?.length && (
                    <tr key={index}>
                      <td>{item?.first?.keys[index]?.value}</td>
                      <td>{item?.second?.keys[index]?.value}</td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </td>
      );
    }

    // Default case if modelType doesn't match any of the above
    return <td style={{ padding: 10 }}>No content available</td>;
  };
  const handleExpand = (expanded: boolean, record: any) => {
    const key = record.key;
    setExpandedRows(
      expanded ? [...expandedRows, key] : expandedRows.filter((k) => k !== key)
    );
  };

  const renderSubmodelData: any = (submodels: any[]) => {
    return submodels.map((submodel, index) => ({
      key: `submodel-${index}`,
      type: submodel?.modelType,
      idShort: submodel?.idShort,
      value: renderTableContent(submodel),
      children:
        submodel.modelType === "SubmodelElementCollection" &&
        submodel.value?.length > 0
          ? renderSubmodelData(submodel.value)
          : undefined,
    }));
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const encodedSearch = id;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}/${encodedSearch}`;
      const response: any = await GET(url);
      if (response?.id) {
        setDetails(response);

        const submodelElement = response?.submodelElements.find(
          (item: any) => item?.modelType === "SubmodelElementCollection"
        );
        console.log("submodelElemetn", submodelElement);
        if (submodelElement?.idShort) {
          const treeDataStructure: any = renderSubmodelData(
            [submodelElement] || []
          );
          setSubmodelElementCollection(treeDataStructure);
          // setSubmodelElementCollection(submodelElement?.value);
        }
        populateForm(response);
      }
    } catch (error: any) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const transformElements = (data: any) => {
    switch (data?.modelType) {
      case "Property":
        return {
          ...transformSubmodels(data),
          value: data?.value,
        };
      case "MultiLanguageProperty":
        setLanguageCount(data?.value?.length);
        return {
          ...transformSubmodels(data),
          multilanguage_property: data?.value?.map(
            (item: any) => item?.language
          ),
          language_description: data?.value?.map((item: any) => item?.text),
        };
      case "File":
        return {
          ...transformSubmodels(data),
          value: data?.value,
          content_type: data?.contentType,
        };
      case "Range":
        return {
          ...transformSubmodels(data),
          valueType: data?.valueType,
          min: data?.min,
          max: data?.max,
        };
      case "ReferenceElement":
        setReferenceCount(data?.value?.keys?.length);
        return {
          ...transformSubmodels(data),
          value: data?.value?.keys?.map((item: any) => item?.value),
        };
      case "RelationshipElement":
        setRelationCount(data?.first?.keys?.length);
        return {
          ...transformSubmodels(data),
          first: data?.first?.keys?.map((item: any) => item?.value),
          second: data?.second?.keys?.map((item: any) => item?.value),
        };
      case "SubmodelElementCollection":
        return {
          ...transformSubmodels(data),
          SubmodelElementCollection: [
            data?.value?.map((item: any) => {
              return transformElements(item);
            }),
          ],
        };
      default:
        return null;
    }
  };

  const populateForm = (data: any) => {
    let transformedElements: any[] = [];
    data?.submodelElements?.map((subElement: any, index: number) => {
      // if (subElement?.modelType === "SubmodelElementCollection") {
      //   transformedSubmodelElements = transformElements(subElement);
      //   form.setFieldsValue({
      //     ...(subElement?.idShort && { id_shortSC: subElement.idShort }),
      //     ...(subElement?.semanticId?.keys?.[0]?.value && {
      //       semantic_idSC: subElement.semanticId.keys[0].value,
      //     }),
      //     ...(subElement?.displayName?.[0]?.text && {
      //       display_nameSC: subElement.displayName[0].text,
      //     }),
      //     ...(subElement?.description?.[0]?.text && {
      //       descriptionSC: subElement.description[0].text,
      //     })
      //   });
      // } else {
      //   transformedElements = transformElements(data);
      // }
      transformedElements.push(transformElements(subElement));
    });
    form.setFieldsValue({
      id: data?.id,
      ...(data?.idShort && { id_short: data.idShort }),
      ...(data?.semanticId?.keys?.[0]?.value && {
        semantic_id: data.semanticId.keys[0].value,
      }),
      ...(data?.displayName?.[0]?.text && {
        display_name: data.displayName[0].text,
      }),
      ...(data?.description?.[0]?.text && {
        description: data.description[0].text,
      }),
      Elements: transformedElements,
      // SubmodelElementCollection: transformedSubmodelElements,
    });
    updateStates(data);
  };

  const mapSubElements = (subElement: any, index: number) => {
    if (subElement.submodelElements) {
      // Map the nested submodelElements
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
        // submodelElements: subElement.submodelElements.map((nestedSubElement: any, nestedIndex: number) =>
        //   mapSubElements(nestedSubElement, nestedIndex)
        // ),
      };
    } else if (subElement.value && Array.isArray(subElement.value)) {
      // Map the value if it's an array
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
        // value: subElement.value.map((nestedSubElement: any, nestedIndex: number) =>
        //   mapSubElements(nestedSubElement, nestedIndex)
        // ),
      };
    } else {
      // No submodelElements or value, just map the element itself
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
      };
    }
  };

  const mapSubElementsRecursive = (elements: any[], parentIndex = 0): any[] => {
    return elements.map((subElement: any, index: number) => {
      const mappedElement = {
        type: subElement.modelType,
        key: Date.now() + parentIndex + index,
        fieldKey: index,
      };

      if (subElement.submodelElements) {
        return {
          ...mappedElement,
          submodelElements: mapSubElementsRecursive(
            subElement.submodelElements,
            index
          ),
        };
      }

      if (subElement.value && Array.isArray(subElement.value)) {
        return {
          ...mappedElement,
          value: mapSubElementsRecursive(subElement.value, index),
        };
      }

      return mappedElement;
    });
  };

  const updateStates = (data: any) => {
    setSubModelElements(
      data.submodelElements.map((subElement: any, index: number) =>
        mapSubElements(subElement, index)
      )
    );
    const submodelElementCollection = data?.submodelElements?.find(
      (subElement: any) => subElement.modelType === "SubmodelElementCollection"
    );
    if (submodelElementCollection) {
      mappedSubmodelElementCollection = mapSubElementsRecursive(
        submodelElementCollection.value
      );
      setSubModelElementsCollection(mappedSubmodelElementCollection);
      localStorage.setItem(
        "submodelElements",
        JSON.stringify(mappedSubmodelElementCollection)
      );
    }
  };

  const transformSubmodels = (data: any) => {
    return {
      id_short: data?.idShort,
      display_name: data?.displayName?.length
        ? data?.displayName[0]?.text
        : null,
      semantic_id: data?.semanticId?.keys?.length
        ? data?.semanticId?.keys[0]?.value
        : null,
      description: data?.description?.length
        ? data?.description[0]?.text
        : null,
    };
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div onClick={() => navigate(`/auth/SubModelScreenFrom/${id}`)}>
          Edit
        </div>
      ),
      key: "1",
    },
    {
      label: <div onClick={() => {}}>Delete</div>,
      key: "2",
    },
  ];

  //   const Tabsitems: TabsProps["items"] = details?.submodels?.map(
  //     (item: any, index: number) => ({
  //       key: index,
  //       label: item?.keys[0]?.value,
  //       children: <SubModalDetails item={item} />,
  //     })
  //   );

  const onChange = (key: string) => {};

  const deleteSubmodel = async (id: any) => {
    try {
      const decodedId = `/${btoa(id)}`;
      const response: any = await DELETE(
        API.SUBMODAL_URL + API.GET_SUBMODELS + decodedId
      );
      message.success("Successfully deleted");
      navigate(-1);
    } catch (error) {
      message.error("Failed to delete submodel");
      console.log(error);
    }
  };

  const columns: any = [
    {
      title: "ID Short",
      dataIndex: "idShort",
      key: "idShort",
      width: "26%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data: any = submodelElementCollection;

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  return (
    <main>
      <Container fluid>
        <PageHeader
          title={details?.idShort}
          breadcum={`Dashboard / Submodels /${details?.idShort} `}
        >
          <div>
            <Dropdown menu={{ items }} placement="bottomLeft">
              <Button style={{ width: 150 }}>Manage</Button>
            </Dropdown>
          </div>
        </PageHeader>

        <Spin spinning={loading}>
          <Card
            title={details?.idShort ? details?.idShort : details?.id}
            extra={
              <div className="d-flex gap-3">
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setExtendedView(!extendedView);
                  }}
                >
                  {extendedView ? "Condensed View" : "Extended View"}
                </Button>
              </div>
            }
          >
            {extendedView ? (
              <>
                <SubmodelDetails
                  details={details}
                  onDelete={(val: any) => {
                    deleteSubmodel(val);
                  }}
                  readonly={true}
                />
                {details?.submodelElements?.length && (
                  <Form form={form} layout="vertical">
                    <div style={{ paddingBottom: "10vh" }}>
                      <br />

                      <Form.List name="Elements">
                        {(fields, { add, remove }) => (
                          <SubmodelElementsDetails
                            fields={fields}
                            subModelElements={subModelElements}
                            form={form}
                            languageCount={languageCount}
                            referenceCount={referenceCount}
                            relationCount={relationCount}
                            mappedSubmodelElementCollection={
                              mappedSubmodelElementCollection
                            }
                          />
                        )}
                      </Form.List>
                    </div>
                  </Form>
                )}
              </>
            ) : (
              <div style={{ overflowX: "auto" }}>
                <Table bordered style={{ margin: 0, width: "100%" }}>
                  <thead>
                    <tr>
                      {/* <th>Submodal Elements</th> */}
                      <th style={{ width: "26%" }}>ID Short</th>
                      <th>Value</th>
                      {/* <th>Description</th> */}
                    </tr>
                  </thead>
                  {details?.submodelElements?.map((item: any) => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            {item?.modelType === "SubmodelElementCollection" ? (
                              <td style={{padding:0}} colSpan={2}>
                                <AntTable
                                  bordered
                                  columns={columns}
                                  dataSource={data}
                                  expandable={{
                                    expandedRowKeys: expandedRows,
                                    onExpand: handleExpand,
                                    expandIcon: ({
                                      expanded,
                                      onExpand,
                                      record,
                                    }) =>
                                      record.type ===
                                      "SubmodelElementCollection" ? (
                                        expanded ? (
                                          <DownOutlined
                                            onClick={(e) => onExpand(record, e)}
                                          />
                                        ) : (
                                          <RightOutlined
                                            onClick={(e) => onExpand(record, e)}
                                          />
                                        )
                                      ) : null,
                                  }}
                                  pagination={false}
                                  showHeader={false}
                                />
                              </td>
                            ) : (
                              <>
                                <td style={{ padding: 10 }}>{item?.idShort}</td>
                                {item?.modelType === "File" && (
                                  <td style={{ padding: 10 }}>{item?.value}</td>
                                )}
                                {item?.modelType === "Property" && (
                                  <td style={{ padding: 10 }}>{item?.value}</td>
                                )}
                                {item?.modelType === "Range" && (
                                  <td style={{ padding: 0 }}>
                                    <Table bordered style={{ margin: 0 }}>
                                      <tbody>
                                        <tr>
                                          <th>Minimum</th>
                                          <th>Maximum</th>
                                        </tr>
                                        <tr>
                                          <td>{item?.min}</td>
                                          <td>{item?.max}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                )}
                                {item?.modelType === "ReferenceElement" && (
                                  <td style={{ padding: 0 }}>
                                    <Table bordered style={{ margin: 0 }}>
                                      <tbody>
                                        {item?.value?.keys?.map(
                                          (singleItem: any) => {
                                            return (
                                              <tr>
                                                <td>{singleItem?.value}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </td>
                                )}
                                {item?.modelType ===
                                  "MultiLanguageProperty" && (
                                  <td style={{ padding: 0 }}>
                                    <Table bordered style={{ margin: 0 }}>
                                      <tbody>
                                        {item?.value?.map((singleItem: any) => {
                                          return (
                                            <tr>
                                              <td>{singleItem?.language}</td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </td>
                                )}
                                {item?.modelType === "RelationshipElement" && (
                                  <td style={{ padding: 0 }}>
                                    <Table bordered style={{ margin: 0 }}>
                                      <tbody>
                                        <tr>
                                          <th>First </th>
                                          <th>Second </th>
                                        </tr>
                                        {item &&
                                          Array.from({
                                            length: Math.max(
                                              item?.first?.keys?.length || 0,
                                              item?.second?.keys?.length || 0
                                            ),
                                          }).map((_, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                {index <
                                                  item?.second?.keys
                                                    ?.length && (
                                                  <tr>
                                                    <td>
                                                      {
                                                        item.first.keys[index]
                                                          ?.value
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        item.second.keys[index]
                                                          ?.value
                                                      }
                                                    </td>
                                                  </tr>
                                                )}
                                              </React.Fragment>
                                            );
                                          })}
                                      </tbody>
                                    </Table>
                                  </td>
                                )}
                                {item?.modelType ===
                                  "SubmodelElementCollection" && (
                                  <td style={{ padding: 0 }}>
                                    {item?.value?.length && (
                                      <AntTable
                                        columns={columns}
                                        dataSource={data}
                                        expandable={{
                                          expandedRowKeys: expandedRows,
                                          onExpand: handleExpand,
                                          expandIcon: ({
                                            expanded,
                                            onExpand,
                                            record,
                                          }) =>
                                            record.type ===
                                            "SubmodelElementCollection" ? (
                                              expanded ? (
                                                <DownOutlined
                                                  onClick={(e) =>
                                                    onExpand(record, e)
                                                  }
                                                />
                                              ) : (
                                                <RightOutlined
                                                  onClick={(e) =>
                                                    onExpand(record, e)
                                                  }
                                                />
                                              )
                                            ) : null,
                                        }}
                                        pagination={false}
                                        showHeader={false}
                                      />
                                    )}
                                  </td>
                                )}
                              </>
                            )}
                            {/* <td style={{ padding: 5 }}>{item?.modelType}</td> */}

                            {/* <td style={{ padding: 10 }}>
                              {item?.description?.[0]?.text}
                            </td> */}
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
                </Table>
              </div>
            )}

            {/* {details?.submodelElements.some(
            (element: any) =>
              element["modelType"] === "SubmodelElementCollection"
          ) && (
            <>
              <Form form={form} layout="vertical">
                <div style={{ paddingBottom: "10vh" }}>
                  <Form.List name="SubmodelElementCollection">
                    {(fields, { add, remove }) => (
                      <SubmodelElementsDetails
                        fields={fields}
                        subModelElements={submodelCollectionElements}
                        form={form}
                        languageCount={languageCount}
                        referenceCount={referenceCount}
                        relationCount={relationCount}
                        type={"SubmodelElementCollection"}
                      />
                    )}
                  </Form.List>
                </div>
              </Form>
            </>
          )} */}
          </Card>
        </Spin>
      </Container>
    </main>
  );
}

export default SubModelsDetails;

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Tabs,
  TabsProps,
  Image,
  Spin,
} from "antd";

import PageHeader from "../../components/pageHeader";
import SubmodalModal from "./componets/submodalModal";
import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utils/apiCall";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";

import DisplayNameModal from "./componets/displayNameModal";
import DescriptionModal from "./componets/descriptionModal";
import SpecificAssetIdModal from "./componets/specificAssetIdModal";
import SubModalDetails from "./componets/subModalDetails";

function DigitalTwinForm() {
  const navigate = useNavigate();
  const { id }: any = useParams();
  const decodedId: any = atob(id);
  const [form] = Form.useForm();
  const [subModal, setSubModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");
  const [details, setDetails] = useState<any>();
  const [selectedSubmodels, setSelectedSubmodels] = useState<any>([]);
  const [subModelsData, setSubModelsData] = useState<any>([]);

  const [descriptionModal, setDescriptionModal] = useState<boolean>(false);
  const [displayNameModal, setDisplayNameModal] = useState<boolean>(false);
  const [specificIdModal, setSpecificIdModal] = useState<boolean>(false);

  const [specificAssetIdCount, setSpecificAssetIdCount] = useState<number>(0);
  const [displayNameCount, setDisplayNameCount] = useState<number>(0);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);

  useEffect(() => {
    if (decodedId !== "0") {
      fetchData();
      fetchCompleteData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const encodedSearch = id;
      const url = `${API.DIGITALTWIN_URL}${API.GET_TWIN}/${encodedSearch}`;
      const response: any = await GET(url);
      if (response?.id) {
        setDetails(response);
        populateForm(response);
        setSelectedSubmodels(response?.submodels);
        setLogo(response?.assetInformation?.defaultThumbnail?.path);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompleteData = async () => {
    try {
      let query = `?order=DESC&idShort=`;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}${query}`;
      const response: any = await GET(url);
      if (response) {
        if (Array.isArray(response?.result)) {
          if (response?.result?.length) {
            setSubModelsData(response?.result);
          } else {
            setSubModelsData([]);
          }
        } else {
          if (response?.id) {
            setSubModelsData([response]);
          } else {
            setSubModelsData([]);
          }
        }
      } else {
        setSubModelsData([]);
      }
    } catch (error: any) {
      setSubModelsData([]);
      console.log(error?.message);
    }
  };

  const populateForm = (data: any) => {
    let specificAssetIdData = [{}];
    let displayNameData = [{}];
    let descriptionData = [{}];
    if (data?.assetInformation?.specificAssetIds?.length) {
      specificAssetIdData = data?.assetInformation?.specificAssetIds?.map(
        (item: any) => ({
          assetId_key: item?.name,
          assetId_value: item?.value,
        })
      );
    }
    if (data?.displayName?.length) {
      displayNameData = data?.displayName?.map((item: any) => ({
        language: item?.language,
        display_name: item?.text,
      }));
    }
    if (data?.description?.length) {
      descriptionData = data?.description?.map((item: any) => ({
        language: item?.language,
        description: item?.text,
      }));
    }
    form.setFieldsValue({
      id: data?.id,
      id_short: data?.idShort,
      logo: data?.assetInformation?.defaultThumbnail?.path,
      kind: data?.assetInformation?.assetKind,
      Global_Asset_ID: data?.assetInformation?.globalAssetId,
      specificAssetIds: specificAssetIdData,
      display_name: displayNameData,
      description: descriptionData,
    });
  };

  const Tabsitems: TabsProps["items"] = selectedSubmodels?.map(
    (item: any, index: number) => {
      const correspondingSubmodel = subModelsData.find(
        (submodel: any) => submodel.id === item.keys?.[0]?.value
      );

      return {
        key: index,
        label: correspondingSubmodel?.idShort || item.keys[0]?.value,
        children: (
          <SubModalDetails
            type="create"
            item={item}
            onDeleteSubModal={(val: any) => deleteSubModal(val)}
          />
        ),
      };
    }
  );

  const operations = (
    <Button type="primary" ghost onClick={() => chooseSubmodel()}>
      Add Submodels
    </Button>
  );

  const chooseSubmodel = async () => {
    setSubModal(!subModal);
  };

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const createtwin = async (values: any) => {
    try {
      let specificAssetIdsdata = [];
      let displayNameData = [];
      let descriptionData = [];
      let subModelData = [];
      if (values?.specificAssetIds) {
        specificAssetIdsdata = values?.specificAssetIds.map((item: any) => {
          return {
            name: item?.assetId_key,
            value: item?.assetId_value,
          };
        });
      }

      if (values?.display_name) {
        displayNameData = values?.display_name.map((item: any) => {
          return {
            language: item?.language,
            text: item?.display_name,
          };
        });
      }

      if (values?.description) {
        descriptionData = values?.description.map((item: any) => {
          return {
            language: item?.language,
            text: item?.description,
          };
        });
      }

      setLoading(true);
      let payload = {
        idShort: values?.id_short,
        id: values?.id,
        modelType: "AssetAdministrationShell",
        assetInformation: {
          assetKind: values?.kind,
          globalAssetId: values?.Global_Asset_ID,
          defaultThumbnail: {
            path: values?.logo,
            contentType: "string",
          },
          specificAssetIds: specificAssetIdsdata,
        },
        displayName: displayNameData,
        description: descriptionData,
        submodels: selectedSubmodels,
      };
      let url = decodedId !== "0" ? `/${id}` : "";
      let method = decodedId !== "0" ? PUT : POST;
      const response: any = await method(
        API.DIGITALTWIN_URL + API.ADD_TWIN + url,
        payload
      );
      if (decodedId !== "0") {
        message.success("Successfully Edited");
        form.resetFields();
        navigate(-1);
        setLoading(false);
      } else {
        if (response?.id) {
          message.success("Successfully created");
          form.resetFields();
          navigate(-1);
          setLoading(false);
        } else {
          message.error("Failed to Create Digital Twin");
          setLoading(false);
        }
      }
    } catch (error) {
      message.error("Failed to Create Digital Twin");
    } finally {
      setLoading(false);
    }
  };

  const handleLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogo(e.target.value);
  };

  const handleModalClose = () => {
    setSpecificIdModal(false);
    setDisplayNameModal(false);
    setDescriptionModal(false);
  };

  const saveSubmodel = (data: any) => {
    setSelectedSubmodels(data);
    setSubModal(!subModal);
  };

  const deleteSubModal = (val: any) => {
    if (val) {
      const result = selectedSubmodels?.filter((Item: any) => {
        return Item?.keys?.[0]?.value != val;
      });
      setSelectedSubmodels(result);
    }
    return;
  };

  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Create Digital Twins"}
          breadcum={"Dashboard / Digital Twins / Create"}
        ></PageHeader>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={createtwin}
            initialValues={{
              specificAssetIds: [{}],
              display_name: [{}],
              description: [{}],
            }}
          >
            <Card>
              <Row>
                <Col sm={2} xs={12}>
                  <Row>
                    <Form.Item name="logo" label="Logo URL">
                      <Input
                        onChange={handleLogo}
                        size="large"
                        placeholder="Enter URL"
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    {logo ? (
                      <Image
                        src={logo}
                        alt="Logo"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: 100,
                          border: `1px solid rgb(223, 223, 223)`,
                          borderRadius: 10,
                        }}
                      />
                    ) : null}
                  </Row>
                </Col>
                <Col sm={5} xs={12}>
                  <Form.Item
                    name={"id"}
                    label="ID"
                    rules={[{ required: true, message: "Please enter ID" }]}
                  >
                    <Input size="large" placeholder="Enter ID" />
                  </Form.Item>
                  <Form.Item label="Kind" name="kind">
                    <Select size="large" placeholder="Select Kind">
                      <Select.Option value="Type">Type</Select.Option>
                      <Select.Option value="Instance">Instance</Select.Option>
                      <Select.Option value={null}>None</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col sm={5} xs={12}>
                  <Form.Item label="ID Short" name="id_short">
                    <Input size="large" placeholder="Enter ID Short" />
                  </Form.Item>
                  <Form.Item name={"Global_Asset_ID"} label="Global Asset ID">
                    <Input size="large" placeholder="Enter ID" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={2} xs={12}></Col>
                <Col>
                  <Button
                    size="large"
                    block
                    onClick={() => setSpecificIdModal(true)}
                    icon={<IoIosAdd />}
                    style={{ fontSize: 14 }}
                  >
                    {`Specific Asset ID${
                      specificAssetIdCount > 0
                        ? `  (${specificAssetIdCount})`
                        : ""
                    }`}
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="large"
                    block
                    onClick={() => setDisplayNameModal(true)}
                    icon={<IoIosAdd />}
                    style={{ fontSize: 14 }}
                  >
                    {`Display Name${
                      displayNameCount > 0 ? ` (${displayNameCount})` : ""
                    }`}
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="large"
                    block
                    onClick={() => setDescriptionModal(true)}
                    icon={<IoIosAdd />}
                    style={{ fontSize: 14 }}
                  >
                    {`Description${
                      descriptionCount > 0 ? ` (${descriptionCount})` : ""
                    }`}
                  </Button>
                </Col>
              </Row>
              <Form.List name={"specificAssetIds"}>
                {(fields, { add, remove }) => {
                  const values = form.getFieldValue("specificAssetIds") || [];
                  const populatedFields = fields.filter((_field, index) => {
                    const value = values[index];
                    return value && value?.assetId_key && value?.assetId_value;
                  });
                  setSpecificAssetIdCount(populatedFields.length);
                  return (
                    <SpecificAssetIdModal
                      form={form}
                      fields={fields}
                      add={add}
                      remove={(name: any) => remove(name)}
                      visible={specificIdModal}
                      onClose={handleModalClose}
                    />
                  );
                }}
              </Form.List>

              <Form.List name={"display_name"}>
                {(fields, { add, remove }) => {
                  const values = form.getFieldValue("display_name") || [];
                  const populatedFields = fields.filter((_fields, index) => {
                    const value = values[index];
                    return value && value?.language && value?.display_name;
                  });
                  setDisplayNameCount(populatedFields.length);
                  return (
                    <DisplayNameModal
                      form={form}
                      fields={fields}
                      add={add}
                      remove={(name: any) => remove(name)}
                      visible={displayNameModal}
                      onClose={handleModalClose}
                    />
                  );
                }}
              </Form.List>

              <Form.List name={"description"}>
                {(fields, { add, remove }) => {
                  const values = form.getFieldValue("description") || [];
                  const populatedFields = fields.filter((_fields, index) => {
                    const value = values[index];
                    return value && value?.language && value?.description;
                  });
                  setDescriptionCount(populatedFields.length);
                  return (
                    <DescriptionModal
                      form={form}
                      fields={fields}
                      add={add}
                      remove={(name: any) => remove(name)}
                      visible={descriptionModal}
                      onClose={handleModalClose}
                    />
                  );
                }}
              </Form.List>

              <br />
              <Tabs
                defaultActiveKey="1"
                items={Tabsitems}
                tabBarExtraContent={operations}
              />
            </Card>
            <div style={{ paddingBottom: "10vh" }} />
            <div className="Footer-ActionBox">
              <div style={{ flex: 1 }}></div>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ marginRight: 20 }}
              >
                Save
              </Button>

              <Button danger type="text" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          </Form>
        </Spin>
      </Container>
      <SubmodalModal
        open={subModal}
        close={() => setSubModal(!subModal)}
        saveSubmodels={(data: any) => saveSubmodel(data)}
        selectedSubmodels={selectedSubmodels}
      />
    </main>
  );
}

export default DigitalTwinForm;

import { Button, Card, message, Popconfirm, Tooltip } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsCopy } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";

const SubmodelDetails = (props: any) => {
  return (
    <Card>
      <Col md={{ offset: 11, span: 1 }} className="d-flex justify-content-end">
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this submodal?"
          onConfirm={() => props?.onDelete(props?.details?.id)}
          onCancel={() => console.log("")}
          okText="Yes"
          cancelText="No"
        >
          {!props?.readonly ? (
            <Button danger className="SubmodelDetails_delete">
              Remove
              <RiDeleteBin6Line size={16} color="red" cursor={"pointer"} />
            </Button>
          ) : null}
        </Popconfirm>
      </Col>
      <Row>
        <Col sm={6} xs={12}>
          <div className="digitalTwinScreen-text4">
            {props?.details?.idShort}
          </div>
          <div className="digitalTwinScreen-text3">{props?.details?.id} &nbsp;&nbsp;&nbsp;
          <BsCopy
                color="green"
                size={20}
                cursor={"pointer"}
                onClick={() => {
                  navigator.clipboard.writeText(
                    props?.details?.id
                  );
                  message.success("ID copied");
                }}
              />
          </div>
          <div className="digitalTwinScreen-text5">
            {props?.details?.description?.[0]?.text &&
              props?.details?.description[0]?.text}
          </div>
        </Col>
        <Col sm={1} xs={12}></Col>
        <Col sm={3} xs={12}>
          {props?.details?.displayName[0]?.text ? (
            <div className="digitalTwinScreen-text3">
              Display Name :{" "}
              {props?.details?.displayName?.[0]?.text &&
                props?.details?.displayName[0]?.text}{" "}
            </div>
          ) : null}
          {props?.details?.semanticId?.keys[0]?.value ? (
            <div className="digitalTwinScreen-text3">
              Semantic ID: {props?.details?.semanticId?.keys[0]?.value}{" "}
              
            </div>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
};

export default SubmodelDetails;
